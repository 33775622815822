import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { checkoutStream, keepaliveStream } from '../apis/index.js';
// import ReactJWPlayer from "react-jw-player";
import jwplayerjs from '../assets/js/jwplayer-8.25.1/jwplayer.js';
import base64DecodeUint8Array from '../utils/base64DecodeUint8Array.js';
import base64EncodeUint8Array from '../utils/base64EncodeUint8Array.js';
// import ExclamationRoundOrange from "./icons/ExclamationRoundOrange";
import CastingView from './CastingView.js';
import CastingIcon from '../assets/CastingIcon.svg';
import ViewIcon from '../assets/view.svg';
// import { TitleIconButtonDialog } from "./common/dialogs/TitleIconButtonDialog";
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { AppContext } from '../AppContext.js';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import CommonButton from './common/buttons/CommonButton.js';
import CommonText from './common/typographies/CommonText';
import Space from './common/Space.js';
import FailIcon from '../assets/payment_fail.svg';
import FullscreenIcon from '../assets/fullscreen_icon.svg';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { breakpoint, WindowSize } from '../utils/responsive';
import Lottie from 'react-lottie';
import * as animationData from '../assets/animation/player_loading.json';
import ReactGA from 'react-ga4';
import MkvPlayer from './MkvPlayer.js';

export default function Player({
  width,
  height,
  title,
  contentId,
  isMultiCDN = true,
  activityId,
  onError,
}) {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { session } = useContext(AppContext);
  const { isLoading, isError, data, error } = useQuery(
    ['checkoutStream', session?.accessToken, contentId, activityId],
    () => checkoutStream(session?.accessToken, contentId, activityId)
  );
  const [viewCount, setViewCount] = useState(null);
  const [sessionError, setSessionError] = useState(false);
  const [checkoutError, setCheckoutError] = useState(false);
  const { t, i18n } = useTranslation(['activity', 'common']);
  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const isMobile = WindowSize() == 'mobile';
  if (window.jwplayer)
    window.jwplayer.key = 'nvNka3tbOHB6pWg0gDCvVeRT4EiRZSBQaYLcCA==';
  const keepalive = async () => {
    console.log('keepalive start');
    let result = await keepaliveStream(
      session?.accessToken,
      contentId,
      data.session
    );
    // result = { status: "fail" }
    if (result) {
      console.log('keepalive success', result);
      window.keepaliveRetry = 0;
      if (result.status == 'fail') {
        // kick user out
        console.log('keepalive kick user out1');
        setSessionError(true);
      }
      if (result.watch) setViewCount(result.watch);
    } else {
      // call api fail
      let retry = window.keepaliveRetry || 0;
      console.log('keepalive fail', retry, result);
      if (retry < 2) {
        window.keepaliveRetry = window.keepaliveRetry
          ? window.keepaliveRetry + 1
          : 1;
      } else {
        // kick user out
        console.log('keepalive kick user out2');
        window.keepaliveRetry = 0;
        setCheckoutError(true);
      }
    }
  };

  const gaKeepalive = async () => {
    ReactGA.event('video_heartbeat', {
      video_title: title,
      video_url: data.streampath,
      video_provider: 'MakeALive',
    });
  };

  useEffect(() => {
    if (data) {
      if (data.watch) setViewCount(data.watch);
      if (data.status != 'success') setCheckoutError(true);
    }
  }, [data]);

  return (
    <Container>
      {window.jwplayer && isMultiCDN && (
        <Helmet>
          <script src="https://cdn.streamroot.io/jwplayer-dash-dna-plugin/1/stable/jwplayer-dash-dna-plugin.js?srKey=8f53cb68-872a-4234-b6b2-80bc082fbe6d"></script>
          <script src="//tools.streamroot.io/usage-graphs/latest/graphs.js"></script>
        </Helmet>
      )}
      <FullScreenContainer handle={handle}>
        {data && data.status == 'success' ? (
          <MkvPlayer
            src={data.streampath}
            token={data.drmtoken}
            contentId={contentId}
            gaKeepaliveFunc={gaKeepalive}
            keepaliveFunc={keepalive}
            keepaliveError={sessionError | checkoutError}
            isLive={true}
            title={title}
            viewCount={viewCount}
          />
        ) : (
          <div
            style={{
              width: '100%',
              aspectRatio: '16/9',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'black',
            }}
          >
            <Loading>
              <Lottie options={lottieOptions} />
            </Loading>
          </div>
        )}
      </FullScreenContainer>

      {sessionError && (
        <Dialog
          PaperProps={{
            style: { margin: 20 },
          }}
          maxWidth="false"
          onClose={() => navigate('/activity')}
          open={sessionError}
        >
          <div
            style={{
              padding: '40px 20px 32px',
              width: 680,
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CommonText
              whiteSpace="pre-line"
              fontWeight={600}
              fontSize="30px"
              mFontSize="24px"
              lineHeight="120%"
            >
              {t('activity:session_error_title')}
            </CommonText>
            <Space size="20px" />
            <CommonText
              whiteSpace="pre-line"
              fontWeight={400}
              fontSize="20px"
              mFontSize="16px"
              lineHeight="140%"
            >
              {t('activity:session_error_desc')}
            </CommonText>
            <Space size="25px" />
            <img src={FailIcon} alt="" />
            <Space size="25px" />
            <CommonButton
              size="large"
              type={'primary'}
              onClick={() => navigate('/activity')}
            >
              {t('common:ok')}
            </CommonButton>
          </div>
        </Dialog>
      )}
      {checkoutError && (
        <Dialog
          PaperProps={{
            style: { margin: 20 },
          }}
          maxWidth="false"
          open={checkoutError}
          onClose={() => setCheckoutError(false)}
        >
          <div
            style={{
              padding: '40px 20px 32px',
              width: 680,
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <CommonText
              whiteSpace="pre-line"
              fontWeight={600}
              fontSize="30px"
              mFontSize="24px"
              lineHeight="120%"
            >
              {t('activity:chceckout_error_title')}
            </CommonText>
            <Space size="20px" />
            <CommonText
              whiteSpace="pre-line"
              fontWeight={400}
              fontSize="20px"
              mFontSize="16px"
              lineHeight="140%"
            >
              {t('activity:chceckout_error_desc')}
            </CommonText>
            <Space size="25px" />
            <img src={FailIcon} alt="" />
            <Space size="25px" />
            <CommonButton
              size="large"
              type={'primary'}
              onClick={() => setCheckoutError(false)}
            >
              {t('common:ok')}
            </CommonButton>
          </div>
        </Dialog>
      )}
      {/* {isMultiCDN && type == "dash" && <div id="streamroot-graphs"></div>} */}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const FullScreenContainer = styled(FullScreen)`
  width: 100%;
  height: 100%;
  position: relative;
`;
const ViewCount = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0em;
  text-align: left;
  padding: 6px 8px;
  color: white;
`;
const Loading = styled.div`
  width: 100px;
  @media ${breakpoint.mobile} {
    width: 50px;
  }
`;
